@use '../../styles/abstracts' as *;

.container {
    width: 100%;
    position: fixed;
    z-index: 999;
    mix-blend-mode: difference;
    @include mq(large){
        bottom: unset;
    }

    a {
        color: $color-primary-light;
    }
}


.nav {
   font-size: $font-size-h5;
    padding: 1rem;

    @include mq(medium) {
        padding: 1rem 2rem;
    }

    &__navBar {
        @include flex(flex-start, space-between);
    }

    li {
        font-size: 1rem;
        font-weight: $font-weight-400;
    }

    .logo {
        fill: $color-primary-light;
        width: 1rem;
    }

    &__email {
        font-size: 0.8rem;
    }

    &__link {
        @include flex(flex-end, flex-end, column);
        flex-direction: column;
      justify-content: flex-end;
 
        @include mq(xsmall) {
            flex-direction: row;

        }
    }
    &__link li {
        margin-left: 1.5rem;
        margin-bottom: 0.5rem;
 

        @include mq(xsmall) {
            flex-direction: row;
            margin-bottom: unset;
        }
    }
}


@keyframes moveGradient {
    0% {
       background-size: 200% 100%;
       background-position: 0% 50%;
   }

   50% {
       background-size: 200% 100%;
       background-position: -100% 50%;
   }

   100% {
       background-size: 200% 100%;
       background-position: -170% 50%;
   }
}
