@use '../../styles/abstracts' as *;

.container {
    @include grid;
    color: $color-primary-light;
    position: relative;
}

.pin {
    @include gridCol(1, 12);
    @include grid;
    grid-template-rows: 10vh 45vh;
    margin-top: 1rem;
    height: 50vh;

    @include mq(xsmall) {
        grid-template-rows: 10vh 50vh;
    }

    @include mq(large) {
        @include gridCol(1, 12);
        margin-top: 10rem;
        grid-template-rows: 10vh 35vh;
    }

    a {
        color: $color-primary-light;
    }

}

.progressBar {
    width: 100%;
    height: 1px;
    background: $color-primary-light;
    @include gridCol(1, 12);
    position: absolute;
}

.nextProject {
    @include flex(flex-start, space-between, column);
    @include gridCol(1, 12);
    height: 10%;
    @include mq(large) {
        @include flex(flex-start, space-between, row);
    }

    p {
        margin-top: 1rem;
    }
}

.nextProject__title {
    justify-self: end;
    align-self: center;
    margin-bottom: 3rem;
    @include gridCol(1, 12);
}

.scrollToTop {
    justify-self: end;
    // margin-top: 1rem;
    cursor: pointer;
    color: $color-secondary-silver;
}

.prevProject {
    cursor: pointer;
    height: 8%;
    @include flex(center, space-between);
    @include gridCol(1, 12);
    justify-self: stretch;
    @include mq(large) {
        @include flex(flex-start, space-between, row);
    }

    div {
        color: $color-secondary-silver;
    }

}