@use '../../../../styles/abstracts' as *;

.components {
    @include grid;
    @include gridCol(2, 10);
    margin-top: 1rem;

    @include mq(small) {
        @include gridCol(5, 4);
    }

    @include mq(large) {
        @include gridCol(2, 4);
        justify-self: center
    }

}

.copy, .clear {
    @include gridCol(1, 12);
    justify-self: self-start;
    margin-bottom: 1rem;
    font-size: 1.5rem
}

.download {
    @include gridCol(1, 12);
    justify-self: self-end;
    margin-bottom: 1rem;
    font-size: 1.5rem
}

.button {
    @include flex(center, center);
    @include gridCol(1,12);
    cursor: pointer;
    margin-top: 1rem;
    margin-bottom: 2rem;
    justify-self: center;
    text-align: center;
    width:8rem;
    height: 3rem;
    padding: 0 1rem;
    background: $color-primary-dark;
    border: 1px solid $color-secondary-silver;
    border-radius: 0.75rem;
    color: $color-primary-light;
}


.container {
    @include grid;
    max-width: 80rem;
    padding: 0 1rem;
    margin: 0 auto;
    color: $color-primary-light;
    position: relative;

    @include mq(medium) {
        padding: 0 2rem;
    }
}

.pin {
    @include gridCol(1, 12);
    @include grid;
    grid-template-rows: 10vh 50vh;
    margin-top: 1rem;
    height: 150vh;

    @include mq(large) {
        @include gridCol(2, 10);
        margin-top: 10rem;
        grid-template-rows: 10vh 30vh;
    }
}

.progressBar {
    width: 0px;
    height: 1px;
    background: $color-primary-light;
    @include gridCol(1, 12);
    position: absolute;

    @include mq(large) {
        @include gridCol(2, 10);
    }
}

.nextProject {
    @include flex(flex-start, space-between, column);
    @include gridCol(1, 12);
    height: 10%;
    @include mq(large) {
        @include flex(flex-start, space-between, row);
    }

    p {
        margin-top: 1rem;
    }
}

.nextProject__title {
    justify-self: end;
    align-self: center;
    @include gridCol(1, 12);
    @include mq(large) {
    }
}

.scrollToTop {
    @include gridCol(1, 12);
    justify-self: end;
    margin-top: 1rem;
    cursor: pointer;
    color: $color-secondary-silver;
}