@use '../../../styles/abstracts' as *;

.hero {
    position: relative;
    top: 0;
    overflow: hidden;
    height: 100vh;
    background: linear-gradient(180deg, rgba(6, 6, 6, 1) 5%, #1C1C1E 50%);
    z-index: 1;
    cursor: grab;

}

.hero:active {
    cursor: grabbing;
}

.greeting {
    @include grid;
    grid-template-rows: repeat(2, 35vh);
    padding: 0 0.5rem;
    height: 100vh;
    position: absolute;
    top: 0;
    color: $color-primary-light;

    h1, h2, p {
        mix-blend-mode: difference;
        z-index: 3;
        pointer-events: none;
    }

    h1 {
        @include gridCol(2, 10);
        align-self: self-end;
        font-size: 1.7rem;
        font-style: normal;
        font-weight: 500;
        margin-bottom: 1rem;

        @include mq(large) {
            @include gridCol(2, 3);
        }

        @include mq(xlarge) {
            font-size: 2.2rem;
        }
    }

    h2 {
        @include gridCol(2, 10);
        font-size: 1.12rem;
        font-style: italic;
        font-weight: 300;

        @include mq(large) {
            @include gridCol(2, 4);
        }

        @include mq(xlarge) {
            font-size: 1.5rem;
        }
    }

    p {
        @include gridCol(9, 3);
        justify-self: end;
        
    }
    
}