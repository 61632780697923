@use '../../../styles/abstracts' as *;

header {
    transition: top ease-in-out 300ms;
}

.container {
    background: $color-primary-dark;
    padding-top: 7rem;

    @include mq(large) {
        padding-top: 15rem;
    }

  

    .btnContainer {
        text-align: center;
        width: fit-content;
        margin-inline: auto;
        color: $color-primary-light;
        cursor: pointer;

        .btn  { 
            padding: 2rem;
            // display: block;
            margin-inline: auto;
            // text-align: center;
 
        }
    }

}

@keyframes moveGradient {
    0% {
       background-size: 200% 100%;
       background-position: 0% 50%;
   }

   50% {
       background-size: 200% 100%;
       background-position: -100% 50%;
   }

   100% {
       background-size: 200% 100%;
       background-position: -170% 50%;
   }
}
