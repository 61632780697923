@use '../../styles/abstracts' as *;

.section {
    color: $color-primary-light;

    @include mq(large) {
        overflow: hidden;
        height: 100vh;
    }
}

.grid {
    @include grid;
    height: 100%;

    aside {
        @include flex(flex-start, space-evenly, column);
        @include gridCol(1, 12);
       
        background: linear-gradient(180deg, rgba(6, 6, 6, 1) 20%, #1C1C1E 100%);
        height: 45vh;
        padding: 4rem 2rem 0 2rem;

        h1 {
            padding-right: 3rem;

            @include mq(large) {
                padding-right: 0;
            }
        }

        h1, h2 {
            color: $color-primary-light;
            font-size: 2.3rem;
            font-weight: 600;
            line-height: 101.023%; /* 3.03069rem */
            letter-spacing: -0.105rem;
        }

        h2 span{
            color: $color-secondary-silver;
            font-family: $font-helvetica;
            font-size: 2rem;
            font-weight: 300;
            line-height: 91.023%;
            letter-spacing: -0.0875rem;
        }

        a {
            color: $color-primary-light;
        }

        @include mq(medium) {
            // @include gridCol(1, 3);
            // background: linear-gradient(90deg, rgba(6, 6, 6, 1) 20%, #1C1C1E 100%);
            // margin-inline: auto;
            // height: 100vh;
            // padding-inline: 4.56rem;
            // padding-top: 0;
    
            h1, h2 {
                font-size: 3rem;
            }
    
            h2 span{
                font-size: 2.2rem;
            }

        }

        @include mq(large) {
            @include gridCol(1, 3);
            background: linear-gradient(90deg, rgba(6, 6, 6, 1) 20%, #1C1C1E 100%);
            margin-inline: auto;
            height: 100vh;
            padding-inline: 4.56rem;
            padding-top: 0;
    
            h1, h2 {
                font-size: 3rem;
            }
    
            h2 span{
                font-size: 2.2rem;
            }

        }
    }
}

.content {
    overflow-y: auto;
    @include gridCol(1, 12);
    @include grid;
    margin-inline: auto;
    padding-inline: 1rem;

    @include mq(medium) {
        padding-inline: 2rem;
    }

    @include mq(large) {
        @include gridCol(4, 9);
        padding-inline: 4rem; 
    }
}

.myImg {
    @include gridCol(1, 12);

    @include mq(large) {
        @include gridCol(1, 4);
        text-align: right;
    }

    img { 
        max-width: 50%;
        border-radius: 0.5rem;
        margin-block: 1rem;

        @include mq(medium) {
            max-width: 30%;
        }

        @include mq(large) {
            max-width: 70%;
        }
    }
}

.moreAboutMe {
    @include gridCol(1, 12);

    @include mq(large) {
        @include gridCol(6 ,6);
    }

    h4 {
        margin-bottom: 1.5rem;
       font-weight: 600;
       color: $color-secondary-silver;
       font-size: 1.6rem;

        @include mq(large) {
            text-align: left;
        }
    }
}

.skillsList, .valueList {
    @include gridCol(1, 12);
    @include grid;

    li {
        @include gridCol(1, 12);


        @include mq(large) {
            @include gridCol(auto, 4);
            align-self: flex-start;
            text-align: center;
            margin-inline: 2rem;
        }

        h4 {
            font-family: $font-helvetica;
            color: $color-secondary-silver;
            font-weight: 500;
            width: 100%;
            margin-block: 1rem;
        }
    }
}

.skillsList {

    li {
        @include stackList;
        @include mq(large) {
            justify-content: center;
        }
    }
}

.hobbies {
    @include gridCol(1, 12);
    @include grid;

}

.hobbyList {

    @include gridCol(1, 12);

    @include mq(large) {
        @include gridCol(7, 6);
        padding-block: 3rem;
    }

    h4 {
        font-family: $font-helvetica;
        color: $color-secondary-silver;
        font-weight: 500;
        width: 100%;
        margin-block: 1rem;
    }

    p {
        font-size: 1.35rem;
        font-weight: 500;
    }
}

.camera {
    @include gridCol(1 ,12);

    @include mq(large) {
        @include gridCol(1, 6);
        grid-row: 1 / span 1;
    }

    text-align: center;
    position: relative;

    img {
        max-width: 70%;
        margin-block: 2rem;
        position: sticky;
        top: 50%;

        @include mq(small) {
            max-width: 75%;
        }

        @include mq(medium) {
            max-width: 45%;
        }

        @include mq(large) {
            max-width: 55%;
        }
    }
}

.click {
    margin-block: 2rem;

    @include mq(small) {
        text-align: center;
    }

    @include mq(large) {
        text-align: left;
        margin-block: 4rem;
    }

    svg {
        width: 7rem;
        height: 7rem;
        transform: rotate(200deg);
        fill: $color-secondary-silver;

        @include mq(medium) {
            transform: rotate(210deg);
        }
        @include mq(large) {
            transform: unset;
        }
    }

    p {
        font-size: 1rem;
        color: $color-secondary-silver;
        text-transform: uppercase;
        margin-bottom: 3rem;

        @include mq(large) {
            margin-bottom: 0;
        }
    }
}