@use '../abstracts' as *;

:root {
    --bodyColor: "#1c1c1e";
}

body {
    height: 100%;
    background: var(--bodyColor);
    transition: background ease-in 300ms;
    font-family: $font-inter;
    line-height: 1.5;
    position: relative;
    scroll-behavior: smooth;
}


.wrapper {
    padding-inline: 1rem;
    margin: 0 auto;
    max-width: 94rem;
    @include mq(medium) {
        padding-inline: 2rem;
    }

    @include mq(large) {
        padding-inline: 4rem;
    }
}  

a {
    text-decoration: none;
    color: $color-primary-dark;
}

.flex {
    display: flex;
}

.lignt {
    background: $color-primary-light !important;
}

.dark {
    background: $color-primary-dark !important;
}

.white {
    background: $color-secondary-white !important;
}

// html.lenis {
//     height: auto;
//   }
  
//   .lenis.lenis-smooth {
//     scroll-behavior: auto;
//   }
  
//   .lenis.lenis-smooth [data-lenis-prevent] {
//     overscroll-behavior: contain;
//   }
  
//   .lenis.lenis-stopped {
//     overflow: hidden;
//   }
  
//   .lenis.lenis-scrolling iframe {
//      pointer-events: none;
//   } 


.active {
    position: relative;
    transition: transform 0.3s ease;

    &:hover {
        animation: moveGradient 2s linear forwards; 
        background: linear-gradient(145deg,$color-primary-light,$color-primary-light, #757575);

        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
    }
}

@keyframes moveGradient {
    0% {
       background-size: 200% 100%;
       background-position: 0% 50%;
   }

   50% {
       background-size: 200% 100%;
       background-position: -100% 50%;
   }

   100% {
       background-size: 200% 100%;
       background-position: -170% 50%;
   }
}
