@use '../../styles/abstracts' as *;

.heading {
    @include gridCol(1, 12);
    border-top: 1px solid $color-secondary-silver;
    color: $color-primary-light;
    margin-block: 4rem 1rem;

    h3 {
        font-weight: 400;
    }


    @include mq(large) {
        margin-block: 10rem 3rem;
    }
}