@use '../../../../styles/abstracts' as *;

.imageContainer {
    // width: 1190px;
    // height: 340px;
   // box-shadow: rgb(0, 0, 0) -20px 20px 70px 70px;
    border-radius: 5px;
    margin-bottom: 46px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex-direction: column;
    @include gridCol(auto, 12);
    grid-row: 2/ span 1;


    @include mq(large) {
        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 81px;
            left: 0px;
            background: radial-gradient(97% 162% at -5% 6%, rgba(11, 11, 15, 0) 0%, rgba(11, 11, 15, 0) 60%, rgb(0, 0, 0) 100%);
            filter: blur(20px);
        
        }
    }


    @include mq(medium) {
        @include gridCol(1, 12);
    }

    @include mq(large) {
        @include gridCol(6, 8);
    }

}
.image {
    position: relative;
    z-index: 1;
    max-width: 100%;
}

.text {
    @include gridCol(1, 12);

    @include mq(medium) {
        @include gridCol(4, 9);
    }
    @include mq(large) {
        @include gridCol(1, 5);
        grid-row: 2/ span 1;
        align-self: center;
    }

}

.projectText {
    @include gridCol(1, 12);
    @include mq(large) {
        @include gridCol(7, 5);
    }

    p {
        margin-bottom: 1rem;
    }
}