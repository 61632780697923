// font
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;900&family=Michroma&display=swap&family=Gilda+Display&display=swap');
@import url('https://fonts.cdnfonts.com/css/helvetica-neue-5');
@import url(https://db.onlinewebfonts.com/c/27ba5e535d5e48e6d9feb62bbf7621d4?family=Helvetica+Neue+Italic); 


$font-michroma: 'Michroma', sans-serif;
$font-inter: 'Inter', sans-serif;
$font-helvetica: "Helvetica Neue Italic", sans-serif;
$font-reenie: 'Gilda Display', serif;
$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-700: 700;
$font-weight-900: 900;
$font-ital: italic;
 
$font-size-h1: 2.5rem;
$font-size-h2: 2rem;
$font-size-h3: 1.75rem;
$font-size-h4: 1.5rem;
$font-size-h5: 1.25rem;
$font-size-h6: 1rem;
$font-size-p: 1rem;
$font-size-14p: 0.875rem;

// Color 
$color-primary-light: #fcf8f4;
$color-primary-dark: #1c1c1e;
$color-highlight-orange: #eb5f31;
$color-secondary-white: #ffffff;
$color-secondary-silver: #b4b4b4;

// Border Radius
$border-radious-sm: 3rem;
$border-radious-md: 5rem;

// Header
$header-height: 2.875rem;