@use '../../styles/abstracts' as *;

footer {
    color: $color-primary-light;
}

.homePadding {

    @include mq(large) {
        padding-top: 8rem;
    }

}

.container {
    @include grid;
    @include gridCol(1, 12);
    grid-template-rows: 10vh 40vh;

    @include mq(medium) {
        grid-template-rows: 5vh 45vh;
    }

    @include mq(large) {
        grid-template-rows: 7vh 40vh;
    }
}

.getInTouch {
    position: relative;
    border-top: 1px solid $color-secondary-silver;
    @include gridCol(1, 12);
    color: $color-primary-light;
    @include flex(flex-start, flex-start, column);

    @include mq(medium) {
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
    }
    p {
        color: $color-secondary-silver;
    }

    svg {
        width: 0.75rem;
        transform: rotate(-45deg);
        fill: $color-secondary-silver;
    }
}


.contact { 
    position: relative;
    color: $color-primary-light;
    font-size: 1.3rem;
    padding: 6rem 0;
    @include gridCol(1, 12);
    align-self: center;
    justify-self: flex-end;

    @include mq(small){
        font-size: 2rem;
        margin-bottom: 5rem;
    }

    @include mq(medium){
        margin-bottom: 2rem;
    }

}

.socialMedia {
    @include gridCol(1, 12);
    @include flex(center, space-between);
    color: $color-primary-light;

    &Link {
        display: flex;

        a{
            color: $color-primary-light;
            padding-left: 1rem;
            display: flex;

            @include mq(medium) {
                padding-left: 1.5rem;

            }


            &:hover svg {
                fill: transparent;
            }
        }

        svg {
            position: unset;
            width: 1rem;
            padding-left: 0.3rem;
        }
    }
}