@use '../../styles/abstracts/' as *;

.icon {
        position: absolute;
        top: 2rem;
        right: 2rem;
        width: 1rem;
        fill: $color-primary-dark;
    
        @include mq(small) {
            top: 2.5rem;
            right: 2.5rem;  
        }
    
        @include mq(large) {
            top: 3rem;
            right: 3rem;
            width: 1.7rem;
        }
}