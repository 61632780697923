@use '../../styles/abstracts' as *;

.container {
    background: $color-primary-dark;
    padding-top: 7rem;

    @include mq(large) {
        padding-top: 15rem;
    }
}

.title {
    grid-column: span 12;
    align-self: flex-end;
    color: $color-primary-light;
}

