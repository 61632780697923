@use '../../styles/abstracts' as *;

* {
    scroll-behavior: auto;
    
}

.canvas {
    @include grid;
    position: relative;
    width: 100%;
    height: 100%;
    gap: 1rem;

    @include mq(medium) {
      row-gap: 4rem;
    }
    @include mq(large) {
      row-gap: unset;
    }
    
    .hobby {

      grid-area: 1/1/span 3/span 12;
      align-self: center;
      margin: 5rem 1rem 0;

      @include mq(large) {
        grid-area: 11/5/span 3/span 5;

      }
   
      span {
        font-family: $font-reenie;
        font-style: normal;
      }
    }
    
    @include mq(large) {
      grid-template-columns: repeat(20, 1fr);
      grid-template-rows: repeat(20, 1fr);
      width: 3000px;
      height: 2000px;
    }

    img {
        max-width: 100%;
        height: auto;
        position: relative;
    }

    .img_1, 
    .img_2,
    .img_3,
    .img_4,
    .img_5,
    .img_6,
    .img_7,
    .img_8,
    .img_9,
    .img_10,
    .img_11,
    .img_12 {
      @include gridCol(1 , 12);

      @include mq(medium) {
        @include gridCol(2 , 10);
      }
    }

    .img_1 {
      @include mq(large) {
        grid-area: 2 / 1 / span 2 / span 2;
      }
    }

    .img_2 {
      @include mq(large) {
      grid-area: 1 / 14 / span 2 / span 2;
      }
    }

    .img_3 {
      @include mq(large) {
      grid-area: 9/2/span 3/span 2;
      }
    }

    .img_4 {
      @include mq(large) {
      grid-area: 14 / 6 / span 2 / span 2;
      }
    }

    .img_5 {
      @include mq(large) {
      grid-area: 8/15/span 3/span 2;
      }
    }

    .img_6 {
      @include mq(large) {
      grid-area: 1 / 5 / span 2 / span 2;
      }
    }

    .img_7 {
      @include mq(large) {
      grid-area: 14/10/span 2/span 3;
      }
    }

    .img_8 {
      @include mq(large) {
      grid-area: 15 / 15 / span 3 / span 2;
      }
    }
    
    .img_9 {
      @include mq(large) {
        grid-area: 2 / 9 / span 2 / span 3;
      }
    }
  
    .img_10 {
      @include mq(large) {
        grid-area:7/11/span 3/span 2;
      }
    }

    .img_11 {
      @include mq(large) {
        grid-area: 16 / 1 / span 2 / span 3;
      }
    }

    .img_12 {
      @include mq(large) {
        grid-area: 8/6/span 3/span 3;
      }
    }


}

.about {
  position: fixed;
  bottom: 1rem;
  left: 2rem;
  mix-blend-mode: difference;
  color: white;

    svg {
      fill: white;
      position: fixed;
      bottom: 3rem;
      left: 2rem;
      top: unset;
      transform: rotate(270deg);
    }
}

.project {
  position: fixed;
  bottom: 1rem;
  right: 2rem;
  mix-blend-mode: difference;
  color: white;

  svg {
    fill: white;
    position: fixed;
    bottom: 3rem;
    right: 2rem;
    top: unset;
  }
}