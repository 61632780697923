@use '../../styles/abstracts' as *;

.projects {
    @include grid;
}

.heading {
    color: $color-primary-light;
    align-self: end;
    margin-bottom: 3rem;

    @include gridCol(auto, 12);

    @include mq(large) {
        margin-bottom: 7rem;
        @include gridCol(1, 12);
    }
}


.card {
    position: relative;
    @include flex(flex-start, center, column);
    @include gridCol(1, 12);
    border-top: 1px solid $color-secondary-silver;
    filter: blur(15px);

    img {
        transition: all 300ms ease-in-out;
    }

    &:hover img {
        transform: translateY(-5px);
    }

    &:hover h4{
        animation: moveGradient 2s linear forwards; 
        background: linear-gradient(145deg,$color-primary-light,$color-primary-light, #757575);

        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
    }
}

@keyframes moveGradient {
    0% {
       background-size: 200% 100%;
       background-position: 0% 50%;
   }

   50% {
       background-size: 200% 100%;
       background-position: -100% 50%;
   }

   100% {
       background-size: 200% 100%;
       background-position: -170% 50%;
   }
}

.preview {
    @include grid;
    width: 100%;
    color: $color-primary-light;

    h4 {
        margin-bottom: 1rem;
    }

}

.content {
    @include gridCol(auto, 12);
    @include grid;

    @include mq(large){
        align-self: self-end;
        margin-bottom: 10rem;
    }

    h5 {
        color: $color-secondary-silver;
        font-size: 0.875rem;
        font-weight: 500;
    }
}

.title {
    @include flex(flex-start, flex-start, column);
    @include gridCol(1, 12);

    @include mq(large){
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        margin-bottom: 2rem;
    }

    p {
        color: $color-secondary-silver;
        font-size: 0.875rem;
        font-weight: 500;
    }
}

.previewImg {
    background: $color-primary-light;
    @include flex(center, center);
    margin-block: 1rem;
    @include gridCol(1, 12);
    overflow: hidden;

    @include mq(large) {
        @include gridCol(5, 8);
        margin-block: unset;
    }

    img {
        width: 80%;
        max-width: 42rem;
        @include gridCol(auto, 12);
        align-self: center;
        justify-self: center;
        @include mq(large){
            margin-bottom: unset;
            @include gridCol(auto, 6);
        }
    }
}

.technology {
    margin-bottom: 1rem
}

.discipline {
    @include stackList;
}

.overview {
    @include gridCol(1, 12);
    margin-bottom: 10rem;

    @include mq(large) {
        margin-right: 1rem;
        grid-area: 2 / 1 / span 1 / 5;
    }
}
