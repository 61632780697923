@use '../../styles/abstracts' as *;

.code {
    position: relative;
    z-index: 1;
    height: 30rem;
    overflow: scroll;
    @include gridCol(auto, 12);
    font-size: 0.7rem;
    border-radius: 1rem;
    margin-bottom: 1rem;
   
    pre {
        border-radius: 1rem;
    }

    @include mq(medium) {
        height: 23rem;
        @include gridCol(1, 12);
        margin-top: 1rem;
    }

    @include mq(large) {
        height: 28rem;
        @include gridCol(2, 10);
    }
}